<script setup lang="ts">
import type { DirectusUser } from "~/node_modules/nuxt-directus/dist/runtime/types/index.d.ts";

const props = withDefaults(
  defineProps<{
    user?: DirectusUser;
    size?: "sm" | "md" | "lg";
    variant?: "solid" | "outline";
  }>(),
  {
    size: "md",
    variant: "solid",
  }
);

const classList = computed(() => [
  "app-avatar",
  "rounded-full",
  "flex items-center justify-center text-center",
  "text-lg leading-[1]",
  "select-none",
  {
    "w-6 h-6": props.size === "sm",
    "w-8 h-8": props.size === "md",
    "w-12 h-12": props.size === "lg",
  },

  {
    "bg-blue text-white contrast-fix": props.variant === "solid",
    "bg-transparent text-black border-[0.0625rem] border-black":
      props.variant === "outline",
  },
]);
</script>

<template>
  <div :class="classList">
    <span v-if="user?.first_name">
      {{ user.first_name[0] }}
    </span>
  </div>
</template>
